// Generated by Framer (0a90a8e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Tab from "https://framerusercontent.com/modules/M42MM5QoUdAgIsIpdSJx/tzXSeR7bB4rZke2ocz0Z/VAMUDsO6f.js";
const TabFonts = getFonts(Tab);

const cycleOrder = ["ueRJ0PAxH", "UhuNNoB8F", "KQFP9MIq6"];

const variantClassNames = {KQFP9MIq6: "framer-v-y1ryrb", ueRJ0PAxH: "framer-v-ya2phe", UhuNNoB8F: "framer-v-1r9n12d"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Tab 1": "ueRJ0PAxH", "Tab 2": "UhuNNoB8F", "Tab 3": "KQFP9MIq6"}

const getProps = ({height, id, width, ...props}) => { return {...props, f72tcvvi2: props.tab2 ?? props.f72tcvvi2 ?? "Tab 2", GuQmBQnFI: props.tab3 ?? props.GuQmBQnFI ?? "Tab 3", MSGp_zYf0: props.tab1 ?? props.MSGp_zYf0 ?? "Tab 1", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ueRJ0PAxH"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tab1?: string;tab2?: string;tab3?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MSGp_zYf0, f72tcvvi2, GuQmBQnFI, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ueRJ0PAxH", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tap17szlvj = activeVariantCallback(async (...args) => {
setVariant("ueRJ0PAxH")
})

const tapxalujt = activeVariantCallback(async (...args) => {
setVariant("UhuNNoB8F")
})

const tap18ejk4e = activeVariantCallback(async (...args) => {
setVariant("KQFP9MIq6")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-xClwA", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-ya2phe", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"ueRJ0PAxH"} ref={ref} style={{...style}} {...addPropertyOverrides({KQFP9MIq6: {"data-framer-name": "Tab 3"}, UhuNNoB8F: {"data-framer-name": "Tab 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-3czbim-container"} layoutDependency={layoutDependency} layoutId={"xxmydzk1k-container"}><Tab height={"100%"} id={"xxmydzk1k"} layoutId={"xxmydzk1k"} title={MSGp_zYf0} variant={"Ho9BqSUe0"} width={"100%"} {...addPropertyOverrides({KQFP9MIq6: {tap: tap17szlvj, variant: "ym0P2kopY"}, UhuNNoB8F: {tap: tap17szlvj, variant: "ym0P2kopY"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-rdibkf-container"} layoutDependency={layoutDependency} layoutId={"egv9jme7Z-container"}><Tab height={"100%"} id={"egv9jme7Z"} layoutId={"egv9jme7Z"} tap={tapxalujt} title={f72tcvvi2} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({UhuNNoB8F: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-1l67s8c-container"} layoutDependency={layoutDependency} layoutId={"bHCCVvUXm-container"}><Tab height={"100%"} id={"bHCCVvUXm"} layoutId={"bHCCVvUXm"} tap={tap18ejk4e} title={GuQmBQnFI} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({KQFP9MIq6: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-xClwA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xClwA .framer-ceisms { display: block; }", ".framer-xClwA .framer-ya2phe { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-xClwA .framer-3czbim-container, .framer-xClwA .framer-rdibkf-container, .framer-xClwA .framer-1l67s8c-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-xClwA .framer-ya2phe { gap: 0px; } .framer-xClwA .framer-ya2phe > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-xClwA .framer-ya2phe > :first-child { margin-left: 0px; } .framer-xClwA .framer-ya2phe > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 275.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"UhuNNoB8F":{"layout":["auto","auto"]},"KQFP9MIq6":{"layout":["auto","auto"]}}}
 */
const FrameriqDV2et24: React.ComponentType<Props> = withCSS(Component, css, "framer-xClwA") as typeof Component;
export default FrameriqDV2et24;

FrameriqDV2et24.displayName = "Elements/Tabs";

FrameriqDV2et24.defaultProps = {height: 49, width: 275.5};

addPropertyControls(FrameriqDV2et24, {variant: {options: ["ueRJ0PAxH", "UhuNNoB8F", "KQFP9MIq6"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, GuQmBQnFI: {defaultValue: "Tab 3", displayTextArea: false, title: "Tab 3", type: ControlType.String}} as any)

addFonts(FrameriqDV2et24, [...TabFonts])